import {
  ConsumerCatalogContainer,
  ContainerDifficultyEnum,
  ContainerOrderBy,
  ContainerOrderByKey,
  OrderByDirection,
  PaginatedCatalogQueryVariables,
} from '@mono/data/codegen/client/graphql-gateway/graphql';

import { CATALOG_PAGE_SIZE } from '../../const';
import { Duration, Price, SelectedFilters } from '../FilterMenus/Filters/types';
import { SortBy } from '../SortByDropdown/types';

export const getFilterCount = (state: SelectedFilters): number =>
  state.difficulty.length +
  state.price.length +
  state.container.filter(
    (c) =>
      c !== ConsumerCatalogContainer.ExternalCourse &&
      c !== ConsumerCatalogContainer.ExternalPath
  ).length +
  (state.duration === Duration.All ? 0 : 1);

export const getQueryVariables = (
  filters: SelectedFilters,
  sortBy: SortBy,
  page: number,
  showExtPaths: boolean
): PaginatedCatalogQueryVariables => {
  return {
    difficulty: getDifficulty(filters.difficulty),
    proExclusive: getProExclusive(filters.price),
    consumerCatalogContainerTypes: getContainerTypes(
      filters.container,
      showExtPaths
    ),
    minDurationHours: getMinDurationHours(filters.duration),
    maxDurationHours: getMaxDurationHours(filters.duration),
    paginate: { perPage: CATALOG_PAGE_SIZE, page },
    order: getOrder(sortBy),
  };
};

const getDifficulty = (difficulty: ContainerDifficultyEnum[]) => {
  // sorting maintains results caching
  return difficulty.length ? difficulty.sort() : undefined;
};

export const getProExclusive = (price: Price[]) => {
  if (price.length === 2 || price.length === 0) {
    // 'all' prices
    return undefined;
  }
  return price.includes(Price.Paid);
};

// TODO: remove showExtPaths during FF cleanup
export const getContainerTypes = (
  containers: ConsumerCatalogContainer[],
  showExtPaths: boolean
) => {
  const args = containers.length
    ? [...containers]
    : [
        // DO NOT pass Journey as a container type
        // Since some paths that are career paths have been converted to journeys but still show up on the catalog under path
        // and then redirect you to the journey page.
        ConsumerCatalogContainer.CareerPath,
        ConsumerCatalogContainer.SkillPath,
        ConsumerCatalogContainer.Track,
      ];

  // treating both Tracks and ExternalCourses as 'Course' selection
  if (args.includes(ConsumerCatalogContainer.Track)) {
    args.push(ConsumerCatalogContainer.ExternalCourse);
  }

  // treating both SkillPaths and ExternalPaths as 'Skill Path' selection
  if (showExtPaths && args.includes(ConsumerCatalogContainer.SkillPath)) {
    args.push(ConsumerCatalogContainer.ExternalPath);
  }

  // sorting maintains results caching
  return args.sort();
};

const durationMinMax: Record<Duration, { min?: number; max?: number }> = {
  [Duration.All]: {},
  [Duration.XS]: { max: 5 },
  [Duration.S]: { min: 5, max: 10 },
  [Duration.M]: { min: 10, max: 20 },
  [Duration.L]: { min: 20, max: 60 },
  [Duration.XL]: { min: 60 },
};

const getMinDurationHours = (duration: Duration) => {
  return durationMinMax[duration]?.min;
};

const getMaxDurationHours = (duration: Duration) => {
  return durationMinMax[duration]?.max;
};

export const getOrder = (sortBy: SortBy): ContainerOrderBy => {
  const queryOrderBy: Record<SortBy, ContainerOrderByKey> = {
    [SortBy.POPULAR]: ContainerOrderByKey.SubmitCount,
    [SortBy.RECENT]: ContainerOrderByKey.CreatedAt,
  };

  return {
    by: queryOrderBy[sortBy],
    direction: OrderByDirection.Desc,
  };
};
